import React from "react";
import UploadFile from "@aspen/ui/uploadFile/UploadFile";
import styles from "@aspen/theme/Profile.module.less";
import { i18nUtil, sendToApp } from "@aspen/libs";

interface IProps {}

export class PageAppUpload extends React.PureComponent<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const intl = i18nUtil.t();
    return (
      <div className={styles.appUploadPage}>
        <div className={styles.appUpload}>
          <UploadFile
            type="public/images"
            text={`${intl["kyc.material.upload"]}(PDF/JPG/PNG/WORD)`}
            getUploadInfo={() => {}}
            echo={[]}
            fromApp={true}
            startUpload={() => {
              sendToApp({ type: "startUpload" });
            }}
            uploadSuccess={(url: string) => {
              sendToApp({ type: "uploadSuccess", data: { url: url } });
            }}
            uploadFailed={(msg: string) => {
              sendToApp({ type: "uploadFailed", data: { message: msg } });
            }}
          />
        </div>
      </div>
    );
  }
}
